import React, { useState } from 'react';
import axios from 'axios';
import ProfileDisplay from './ProfileDisplay';
import { useAuth0 } from '@auth0/auth0-react';
import '../styles/ProfileForm.css';

const ProfileForm = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [username, setUsername] = useState('');
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchProfile = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = await getAccessTokenSilently();
            const response = await axios.post(apiUrl, { username }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const profileData = response.data.discProfile.message.content;
            if (profileData) {
                setProfile(profileData);
            } else {
                setError('Invalid DISC profile data received');
            }
        } catch (err) {
            setError(`Failed to fetch profile: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username) {
            fetchProfile();
        } else {
            setError('Please provide a LinkedIn username.');
        }
    };

    return (
        <div className="page-container">
            {/* Conditional Rendering for Form */}
            {!profile ? (
                <div className="form-container">
                    <h2 className="form-title">Submit Your LinkedIn Username</h2>
                    <p className="form-description">
                        Enter your LinkedIn username to fetch and view your DISC profile insights.
                    </p>
                    <form onSubmit={handleSubmit} className="form">
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder="LinkedIn Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="button-group">
                            <button type="submit" className="submit-btn">
                                Submit
                            </button>
                        </div>
                    </form>
                    {error && <p className="feedback-message">{error}</p>}
                </div>
            ) : (
                <ProfileDisplay profile={profile} />
            )}

            {/* Loading Overlay */}
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                    <div className="loading-text">Loading profile... Please wait</div>
                </div>
            )}
        </div>
    );
};

export default ProfileForm;