import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/Layout.css';

const Layout = ({ children, isProfileComplete }) => {
    return (
        <div className="layout">
            <Header isProfileComplete={isProfileComplete} />
            <main className="main-content">{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;