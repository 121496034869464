import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './components/HomePage';
import ProfileForm from './components/ProfileForm';
import CompleteProfile from './components/CompleteProfile';
import ProtectedRoute from './components/ProtectedRoute';
import UserProfileDisplay from './components/UserProfileDisplay';
import Layout from './components/Layout';

const App = () => {
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const [isProfileComplete, setIsProfileComplete] = useState(null); // Default is `null` to indicate "not checked"
    const [profileChecked, setProfileChecked] = useState(false);

    useEffect(() => {
        const fetchProfileStatus = async () => {
            if (!isAuthenticated) {
                console.log("User is not authenticated.");
                setProfileChecked(true); // Allow profile check to complete
                return;
            }

            try {
                console.log("Fetching profile status...");
                const token = await getAccessTokenSilently();
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/user/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Fetched profile data:", data);
                    setIsProfileComplete(data.profileComplete); // Set the correct value
                } else {
                    console.error("Failed to fetch profile status. Status:", response.status);
                }
            } catch (error) {
                console.error("Error fetching profile status:", error);
            } finally {
                setProfileChecked(true); // Mark as checked regardless of the result
            }
        };

        fetchProfileStatus();
    }, [isAuthenticated, getAccessTokenSilently]);

    // Debugging Logs
    console.log("App Component Rendered");
    console.log("isAuthenticated:", isAuthenticated);
    console.log("isProfileComplete:", isProfileComplete);
    console.log("profileChecked:", profileChecked);

    // Show loading screen while checking profile status
    if (isLoading || !profileChecked) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Layout isProfileComplete={isProfileComplete}>
                <Routes>
                    <Route
                        path="/"
                        element={<HomePage isProfileComplete={isProfileComplete} />}
                    />
                    <Route
                        path="/profile"
                        element={
                            <ProtectedRoute
                                component={ProfileForm}
                                isProfileComplete={isProfileComplete}
                            />
                        }
                    />
                    <Route
                        path="/complete-profile"
                        element={
                            <CompleteProfile setIsProfileComplete={setIsProfileComplete} />
                        }
                    />
                        <Route path="/my-disc-profile" element={<UserProfileDisplay />} />
                </Routes>
                
            </Layout>
        </Router>
    );
};

export default App;