import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} Cognix. All Rights Reserved.</p>
            <p>Empowering Growth Through Behavioral Insights</p>
            <p>
        <a href="/terms">Terms</a> | <a href="/privacy">Privacy</a>
    </p>
        </footer>
    );
};

export default Footer;