import React from 'react';
import { useLocation } from 'react-router-dom';
import ProfileDisplay from './ProfileDisplay';

const UserProfileDisplay = () => {
    const location = useLocation();
    const profile = location.state?.profile;

    console.log('Received profile in UserProfileDisplay:', profile);

    if (!profile) {
        return <div>No DISC profile data found!</div>;
    }

    return <ProfileDisplay profile={profile} />;
};

export default UserProfileDisplay;