import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import '../styles/CompleteProfile.css';

const CompleteProfile = ({ setIsProfileComplete }) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        if (user) {
            setFirstName(user.given_name || '');
            setLastName(user.family_name || '');
        }
    }, [user]);

    const extractLinkedInUsername = (url) => {
        try {
            const matches = url.match(/linkedin\.com\/in\/([^/]+)/);
            return matches ? matches[1] : null;
        } catch {
            console.error('Error extracting LinkedIn username from URL:', url);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = await getAccessTokenSilently();
            const username = extractLinkedInUsername(linkedinUrl);

            if (!username) {
                setError('Invalid LinkedIn URL');
                return;
            }

            console.log('Updating user profile...');
            const profileUpdateResponse = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/api/user/complete-profile`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ firstName, lastName, linkedinUrl }),
                }
            );

            if (!profileUpdateResponse.ok) {
                throw new Error('Failed to update profile');
            }

            console.log('Fetching DISC profile...');
            const discProfileResponse = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/api/disc-profile`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ username }),
                }
            );

            if (!discProfileResponse.ok) {
                throw new Error('Failed to fetch DISC profile');
            }

            const discProfile = await discProfileResponse.json();
            console.log('Fetched DISC profile:', discProfile);

            if (!discProfile?.discProfile?.message?.content) {
                throw new Error('Invalid DISC profile data received');
            }

            setIsProfileComplete(true);
            navigate('/my-disc-profile', { state: { profile: discProfile.discProfile.message.content } });
        } catch (err) {
            console.error('Error completing profile:', err);
            setError('Failed to complete profile or fetch DISC profile.');
        }
    };

    return (
        <div className="complete-profile-container">
            <h2>Complete Your Profile</h2>
            <form onSubmit={handleSubmit} className="complete-profile-form">
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="linkedinUrl">LinkedIn Profile URL</label>
                    <input
                        type="url"
                        id="linkedinUrl"
                        value={linkedinUrl}
                        onChange={(e) => setLinkedinUrl(e.target.value)}
                        placeholder="https://www.linkedin.com/in/your-profile"
                        required
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="submit-btn">
                    Complete Profile
                </button>
            </form>
        </div>
    );
};

export default CompleteProfile;