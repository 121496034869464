import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = ({ isProfileComplete }) => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

    console.log("[Header] Rendered with props:");
    console.log(" - isAuthenticated:", isAuthenticated);
    console.log(" - isProfileComplete:", isProfileComplete);

    return (
        <header className="header">
            <div className="brand">
                <Link to="/" className="brand-link">
                    <img src="/logo_cropped.svg" alt="Cognix Logo" className="logo-image" />
                    <span className="logo">Cognix</span>
                </Link>
            </div>
            <nav className="nav">
                <Link to="/" className="nav-link">About Us</Link>
                <Link to="/" className="nav-link">Demo</Link>
                {isAuthenticated && (
                    <div className="dropdown">
                        <span className="dropdown-btn">Tools</span>
                        <div className="dropdown-content">
                            <Link
                                to={isProfileComplete ? "/profile" : "/complete-profile"}
                                className="dropdown-link"
                                onClick={() =>
                                    console.log(
                                        `Navigating to ${
                                            isProfileComplete ? "/profile" : "/complete-profile"
                                        }`
                                    )
                                }
                            >
                                DISC Profiling
                            </Link>
                        </div>
                    </div>
                )}
                {isAuthenticated ? (
                    <>
                        <span className="user-info">Logged in as: {user.name}</span>
                        <button
                            className="logout-btn"
                            onClick={() => {
                                console.log("[Header] Logging out user...");
                                logout({ returnTo: window.location.origin });
                            }}
                        >
                            Log Out
                        </button>
                    </>
                ) : (
                    <button
                        className="login-btn"
                        onClick={() => {
                            console.log("[Header] Redirecting to login...");
                            loginWithRedirect();
                        }}
                    >
                        Log In
                    </button>
                )}
            </nav>
        </header>
    );
};

export default Header;