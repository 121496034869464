import React from 'react';
import '../styles/HomePage.css';

const HomePage = () => {
    return (
        <>
            {/* Hero Section */}
            <section className="hero">
                <img className="hero-background" src="/heroimage.png" alt="Hero" />
                <div className="hero-content">
                    <h1 className="hero-title">Unlock Insights with DISC Profiles</h1>
                    <p className="hero-subtitle">
                        Discover leadership, personality, and behavioral insights to drive growth and success.
                    </p>
                    <button 
                        className="cta-btn" 
                        onClick={() => window.scrollTo({ top: 800, behavior: 'smooth' })}
                    >
                        Get Started
                    </button>
                </div>
            </section>

            {/* Features Section */}
            <section className="features">
                <div className="feature">
                    <h3>Leadership Insights</h3>
                    <p>Unlock leadership potential through DISC profiling.</p>
                </div>
                <div className="feature">
                    <h3>Team Productivity</h3>
                    <p>Discover team strengths and work styles to enhance collaboration.</p>
                </div>
                <div className="feature">
                    <h3>Data-Driven Growth</h3>
                    <p>Leverage behavioral insights to drive results.</p>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="testimonials">
                <h2 className="section-title">What Our Users Say</h2>
                <div className="testimonial">
                    <p>"Cognix helped us unlock the full potential of our team!"</p>
                    <span>- CEO, ABC Corp</span>
                </div>
                <div className="testimonial">
                    <p>"The DISC profiling insights were game-changing for our growth strategy."</p>
                    <span>- Head of HR, XYZ Inc</span>
                </div>
            </section>
        </>
    );
};

export default HomePage;