import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ component: Component, isProfileComplete }) => {
    const { isAuthenticated } = useAuth0();

    console.log("ProtectedRoute Rendered");
    console.log("isAuthenticated:", isAuthenticated);
    console.log("isProfileComplete:", isProfileComplete);

    if (!isAuthenticated) {
        console.log("User is not authenticated. Redirecting to login...");
        return <Navigate to="/login" />;
    }

    if (!isProfileComplete) {
        console.log("Profile is incomplete. Redirecting to complete-profile...");
        return <Navigate to="/complete-profile" />;
    }

    console.log("Rendering protected component...");
    return <Component />;
};

export default ProtectedRoute;